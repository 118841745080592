import { inject } from "@angular/core";
import { ResolveFn } from "@angular/router";

import { AppWorkflowService, AppWorkflowType } from "@app/services";

export function resolveWorkflow(
  workflow: AppWorkflowType
): ResolveFn<AppWorkflowType> {
  return () => inject(AppWorkflowService).setWorkflowType(workflow);
}
