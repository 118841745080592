import { Route } from "@angular/router";

import { resolveBranding, resolveChannel, resolveWorkflow } from "./resolvers";
import { boundUserGuard, isLoggedInGuard, preSignOutGuard } from "./guards";
import { NotFoundComponent } from "./features/notfound/notfound.component";
import { environment } from "@environment";

const authFormsRoute: Route = {
  path: "",
  loadChildren: () =>
    import("./forms/forms.module").then((m) => m.AuthenticationFormsModule),
};

const arenaRoute: Route = {
  path: "arena",
  canActivate: [preSignOutGuard],
  resolve: {
    workflow: resolveWorkflow("ARENA"),
    scope: resolveChannel(true),
  },
  children: [authFormsRoute],
};

const studioRoute: Route = {
  path: "studio",
  resolve: { workflow: resolveWorkflow("STUDIO"), scope: resolveChannel() },
  canActivate: [preSignOutGuard],
  children: [
    {
      path: "invites",
      title: "Manage your invite",
      canActivate: [boundUserGuard("/studio")],
      async loadChildren() {
        const res = await import("./features/invites/invites.module");
        return res.InvitesModule;
      },
    },
    authFormsRoute,
  ],
};

const tvRoute: Route = {
  path: "tv",
  canActivate: [preSignOutGuard],
  resolve: {
    scope: resolveChannel(),
    workflow: resolveWorkflow("TV"),
  },
  children: [
    {
      path: "privacy",
      canActivate: [isLoggedInGuard("/tv")],
      title: "Let's stay in touch",
      async loadComponent() {
        const res = await import("./features/consents/consents.component");
        return res.ConsentsComponent;
      },
    },
    {
      path: "integrations",
      title: "Manage your integration",
      canActivate: [isLoggedInGuard("/tv")],
      async loadChildren() {
        const res = await import("./features/integrations/integrations.module");
        return res.IntegrationsModule;
      },
    },
    authFormsRoute,
  ],
};

const routes: Route[] = [
  {
    path: "",
    resolve: {
      branding: resolveBranding(environment.meta.defaultBranding, undefined),
    },
    children: [
      {
        path: "404",
        component: NotFoundComponent,
        title: "Not found",
      },
      arenaRoute,
    ],
  },
];

routes.push(studioRoute);
routes.push(tvRoute);
/** This must always be the last route */
routes.push({ path: "**", redirectTo: "404" });

/** Map of paths to the main routes  */
const APP_ROUTES = {
  ARENA: arenaRoute.path!,
  STUDIO: studioRoute.path!,
  TV: tvRoute.path!,
} as const;

export { APP_ROUTES, routes };
