import { AuthErrors } from "@yoimo/interfaces";

export type AuthProvider = "google" | "facebook";

export const AUTH_PROVIDER_PLACEHOLDER = "$PROVIDER";

export const AUTH_ERRORS = {
  "auth/account-exists-with-different-credential": `Looks like you previously signed up with ${AUTH_PROVIDER_PLACEHOLDER} using the same email.`,
  "auth/email-already-in-use": "This account already exists",
  "auth/invalid-action-code":
    "Password reset link has expired, request a new reset link.",
  "auth/invalid-credential": "You have entered an invalid email or password",
  "auth/invalid-email": "You have entered an invalid email or password",
  "auth/missing-credentials": "Please type your email and/or password",
  "auth/missing-email": "Please type your email",
  "auth/operation-not-allowed": "You don't have permissisons to do this",
  "auth/popup-closed-by-user": "",
  "auth/too-many-requests":
    "You've tried too many times. Please try again later",
  "auth/user-cancelled": "You've cancelled the action",
  "auth/user-disabled":
    "Your user has been disabled. Please contact our Customer Success team.",
  "auth/user-not-found": "This email isn't connected to an account",
  "auth/wrong-password": "You have entered an invalid email or password",
  "joymo-auth/accept-tos": "You must accept the terms to continue",
  "joymo-auth/bad-consent-requests": "Failed to save your consent responses",
  "joymo-auth/missing-email-permission": "Email permission is required",
  "joymo-auth/missing-password": "You have entered invalid password",
  "joymo-auth/passwords-dont-match": "Passwords do not match",
  [AuthErrors.Unauthorized]: "Not authorized",
  [AuthErrors.BadParams]: "Bad params",
  [AuthErrors.ChannelNotFound]: "Channel not found",
  [AuthErrors.NoDomainMatch]: "Bad redirection domain",
  [AuthErrors.UnsafeProtocol]: "Bad HTTP protocol",
};
