import { LoggingService } from "@app/services";

export const AUTH_QUERY_PARAMS = {
  BOUND_EMAIL: "bu",
  CHANNEL_ID: "channel_id",
  OOB_CODE: "oobCode",
  REDIRECT_URL: "redirect_url",
  RESULT: "result",
  RETURN_TO: "return_to",
  SLUG: "slug",
} as const;

export const KNOWN_PATH_PARAMS = {
  CHANNEL_ID: "channelId",
} as const;

const _queryParamValues = Object.values(AUTH_QUERY_PARAMS);
type QueryParam = (typeof _queryParamValues)[number];

/** @throws If param is not found and no {@link fallbackValue} is specified */
export function getQueryParam(
  param: QueryParam,
  fallbackValue?: string
): string {
  const value = new URL(location.href).searchParams.get(param);

  if (value) return value;
  if (!fallbackValue) throw new Error(`Query param "${param}" is required`);

  LoggingService.setBreadcrumb(
    `QueryParam "${param}" was marked as required but not found - using fallback value "${fallbackValue}"`
  );
  return fallbackValue;
}
