import { ApplicationConfig, ErrorHandler } from "@angular/core";
import { provideAuth, getAuth } from "@angular/fire/auth";
import { provideFirebaseApp, initializeApp } from "@angular/fire/app";
import { provideFirestore, getFirestore } from "@angular/fire/firestore";
import { provideFunctions, getFunctions } from "@angular/fire/functions";
import {
  provideHttpClient,
  HTTP_INTERCEPTORS,
  withFetch,
} from "@angular/common/http";
import { provideRouter, TitleStrategy } from "@angular/router";

import { PARSER_IMPLEMENTATION_TOKEN } from "@yoimo/joymo-ui";

import { APIInterceptor, TextParser } from "./utils";
import { routes } from "./app.routes";
import { environment } from "@environment";
import { LoggingService, MetaService } from "./services";

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    provideAuth(() => getAuth()),
    provideFunctions(() =>
      getFunctions(undefined, environment.firebase.locationId)
    ),
    provideHttpClient(withFetch()),
    { provide: ErrorHandler, useClass: LoggingService },
    { provide: HTTP_INTERCEPTORS, useClass: APIInterceptor, multi: true },
    { provide: PARSER_IMPLEMENTATION_TOKEN, useClass: TextParser },
    { provide: TitleStrategy, useClass: MetaService },
  ],
};
