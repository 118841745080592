/* istanbul ignore file */

import { Firestore } from "@angular/fire/firestore";
import { inject, Injectable } from "@angular/core";
import { Functions } from "@angular/fire/functions";

import {
  forkJoin,
  map,
  Observable,
  of,
  ReplaySubject,
  switchMap,
  take,
  tap,
} from "rxjs";
import {
  checkTermsConsent,
  checkUserConsent,
  TermsConsentCheck,
  updateUserConsent,
  UserConsentCheck,
} from "@yoimo/client-sdk/users";
import {
  getChannelPublicSettings,
  getUserConsentEntry,
  getUserConsentRequests,
} from "@yoimo/client-sdk/channels";

import {
  ChannelTerms,
  UpdateUserConsentRequest,
  UpdateUserConsentResponse,
  UserConsentRequest,
  UserConsentSettings,
} from "@yoimo/interfaces";

type ConsentsGroup = UserConsentSettings["groups"][number] & {
  items: UserConsentRequest[];
};

@Injectable({ providedIn: "root" })
export class ConsentService {
  private readonly hasConsentEntry$ = new ReplaySubject<boolean>(1);

  private readonly ff = inject(Functions);
  private readonly fs = inject(Firestore);

  getChannelRequests$(
    channelId: string
  ): Observable<[UserConsentRequest[], ChannelTerms[]]> {
    return this.getConsentSettings$(channelId).pipe(
      map((res) => (res ? [res.requests, res.terms] : [[], []]))
    );
  }

  getConsentGroups$(channelId: string): Observable<{
    grouped: ConsentsGroup[];
    ungrouped: UserConsentRequest[];
  } | null> {
    return this.getConsentSettings$(channelId).pipe(
      map((res) => {
        if (!res) return null;

        return {
          ungrouped: res.requests.filter((req) => req.group === null),
          grouped: res.groups.map((group) => ({
            ...group,
            items: res.requests.filter((req) => req.group === group.id),
          })),
        };
      })
    );
  }

  /** Check if there are any outstanding terms/consents pending to be accepted */
  isConsentEntryPending$(
    channelId: string,
    userId: string
  ): Observable<boolean> {
    return this.getOrCreateConsentEntry$(channelId, userId).pipe(
      map((consentEntry) => {
        return consentEntry
          .flat()
          .some((check) => check.required.length || check.needUpdate.length);
      })
    );
  }

  updateConsents$(
    payload: UpdateUserConsentRequest
  ): Observable<UpdateUserConsentResponse> {
    return this.hasConsentEntry$.pipe(
      take(1),
      switchMap((hasConsentEntry) => {
        return updateUserConsent(this.ff, {
          ...payload,
          merge: hasConsentEntry,
        });
      }),
      tap((res) => this.hasConsentEntry$.next(res.success))
    );
  }

  private getOrCreateConsentEntry$(
    channelId: string,
    userId: string
  ): Observable<[UserConsentCheck, TermsConsentCheck]> {
    return getUserConsentEntry(this.fs, channelId, userId).pipe(
      switchMap((consentEntry) => {
        if (consentEntry) {
          this.hasConsentEntry$.next(true);
          return of(consentEntry);
        }

        this.hasConsentEntry$.next(false);
        return this.updateConsents$({
          channelId,
          responses: [],
          terms: [],
        }).pipe(map(() => consentEntry));
      }),
      switchMap((consentEntry) => {
        return forkJoin([
          getChannelPublicSettings(this.fs, channelId),
          of(consentEntry),
        ]);
      }),
      map(([channelSettings, consentEntry]) => [
        checkUserConsent(channelSettings, consentEntry),
        checkTermsConsent(channelSettings, consentEntry),
      ])
    );
  }

  private getConsentSettings$(
    channelId: string
  ): Observable<UserConsentSettings | null> {
    return getChannelPublicSettings(this.fs, channelId).pipe(
      map((settings) => {
        if (!settings?.userConsent) return null;
        return getUserConsentRequests(settings);
      })
    );
  }
}
