import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { UIState } from "@yoimo/joymo-ui";
import { AUTH_ERRORS as errors } from "../model";
import { LoggingService } from "./logging.service";
import { FirebaseError } from "@angular/fire/app";

@Injectable({ providedIn: "root" })
export class AppStateService {
  private _error$ = new BehaviorSubject<string | null>(null);
  private _state$ = new BehaviorSubject<UIState>("idle");

  readonly error$ = this._error$.asObservable();
  readonly state$ = this._state$.asObservable();

  setError = (error: string | null): void => {
    if (error) {
      this.setState("error");
      if (error === errors["auth/user-cancelled"]) return;
      if (error in errors) {
        // Get mapped message
        this._error$.next((errors as Record<string, string>)[error]);
        return;
      }
    }

    this._error$.next(error);
  };

  /**
   * @param state New state
   * @param because Internal tracking
   */
  setState = (state: UIState, because?: string): void => {
    if (because) LoggingService.setBreadcrumb(because);
    this._state$.next(state);
  };

  resetState = (): void => {
    this._state$.next("idle");
    this._error$.next(null);
  };
}
