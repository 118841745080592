/* istanbul ignore file */

import { ScopeService } from "@app/services/scope.service";
import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { RouterStateSnapshot, TitleStrategy } from "@angular/router";
import { WindowService } from "@yoimo/joymo-ui";
import { environment } from "@environment";

const { favicons } = environment.behavior.testFeatures;

@Injectable({ providedIn: "root" })
export class MetaService extends TitleStrategy {
  constructor(
    private readonly title: Title,
    private scopeService: ScopeService,
    private windowService: WindowService
  ) {
    super();
  }

  setTitle(title: string | undefined, suffix: string): void {
    if (!title) throw new TypeError("Missing title");
    this.title.setTitle([title, suffix].join(" - "));
  }

  setFavicon(channelId: string): void {
    const { document } = this.windowService;
    const iconEl = document.createElement("link");
    iconEl.setAttribute("rel", "shortcut icon");
    iconEl.setAttribute("href", favicons[channelId] || favicons.DEFAULT);
    document.head.appendChild(iconEl);
  }

  override updateTitle(routerState: RouterStateSnapshot): void {
    this.scopeService.getChannel$().subscribe((channel) => {
      const title = this.buildTitle(routerState);
      this.setTitle(title, channel?.name || environment.meta.company);
    });
  }
}
