import { ResolveFn } from "@angular/router";
import { inject } from "@angular/core";

import {
  BrandingOptions,
  BrandingService,
  WindowService,
  getBrandingStyleSheet,
} from "@yoimo/joymo-ui";

import { MetaService } from "@app/services";

export function resolveBranding(
  branding: BrandingOptions,
  faviconId: string | undefined
): ResolveFn<BrandingOptions> {
  return (): BrandingOptions => {
    const brandingService = inject(BrandingService);
    const metaService = inject(MetaService);
    const windowService = inject(WindowService);

    applyTheme(branding, faviconId || "default", [
      brandingService,
      metaService,
      windowService,
    ]);
    return branding;
  };
}

function applyTheme(
  branding: BrandingOptions,
  favicon: string,
  services: [BrandingService, MetaService, WindowService]
): void {
  const [brandingService, metaService, windowService] = services;

  metaService.setFavicon(favicon || "default");
  brandingService.setTheme(branding, true);

  const { document } = windowService;
  const stylesheet = getBrandingStyleSheet(document, brandingService);
  document.head.appendChild(stylesheet);
}
