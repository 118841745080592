import { WindowService } from "@yoimo/joymo-ui";

function getNormalizedUserAgent(): string | null {
  if (!WindowService.isWindowAvailable) return null;
  return navigator.userAgent.toLowerCase();
}

/**
 * Taken from [this stackoverflow answer](https://stackoverflow.com/a/9039885/8609340)
 *
 * @deprecated As `Navigator.platform` is [getting deprecated](https://developer.mozilla.org/en-US/docs/Web/API/Navigator/platform).
 * To fix this function, refer to [Navigator.userAgentData](https://developer.mozilla.org/en-US/docs/Web/API/Navigator/userAgentData)
 */
export function iOS(): boolean {
  const userAgent = getNormalizedUserAgent();

  if (!userAgent) return false;

  const isRealDevice = /ip(ad|hone|od)/gi.test(userAgent);
  const isOlderIpad = userAgent.includes("Mac") && "ontouchend" in document;
  const isSimulator = /simulator/gi.test(navigator.platform);

  return isRealDevice || isOlderIpad || isSimulator;
}

export function isMobile(): boolean {
  const userAgent = getNormalizedUserAgent();
  if (!userAgent) return false;
  if ("mobile" in navigator) return Boolean(navigator.mobile);

  return /mobile|android|webos|iphone|ipad|opera mini|crios/gi.test(userAgent);
}

export function isWebview(): boolean {
  if (!isMobile()) return false;

  const userAgent = getNormalizedUserAgent();
  if (!userAgent) return false;
  if (iOS()) {
    // iOS webview
    return !userAgent.match(/safari/i);
  }
  if (userAgent.match(/Android.*Version\/[\d.]+/) || userAgent.match(/wv/)) {
    // Android webview
    return true;
  }
  return false;
}
