import { Component, OnInit } from "@angular/core";

import { BrandingService } from "@yoimo/joymo-ui";

import { environment } from "@environment";

@Component({
  selector: "app-notfound",
  templateUrl: "./notfound.component.html",
})
export class NotFoundComponent implements OnInit {
  constructor(private brandingService: BrandingService) {}

  ngOnInit(): void {
    this.brandingService.setTheme(environment.meta.defaultBranding, true);
  }
}
