import { Firestore } from "@angular/fire/firestore";

import { combineLatest, map, Observable, of, switchMap } from "rxjs";

import {
  getChannelPublicSettings,
  getExternalPlanProviders,
} from "@yoimo/client-sdk/channels";
import { getUsersSubscriptions } from "@yoimo/client-sdk/subscriptions";
import { isSubscriptionStillValid } from "@yoimo/client-sdk/business-logic";
import { UserInfoWithDocId } from "@yoimo/client-sdk/users";

export function getSport80Settings$(
  fs: Firestore,
  channelId: string,
  userId: string
) {
  return getChannelPublicSettings(fs, channelId).pipe(
    map((settings) => {
      return getExternalPlanProviders(settings, userId).find(
        (p) => p.id === "sport80" && p.enabled
      );
    })
  );
}

function userHasSport80(
  channelId: string,
  userInfo: UserInfoWithDocId
): boolean {
  if (!userInfo.externalIntegrationsData?.payment?.sport80) return false;
  return channelId in userInfo.externalIntegrationsData.payment.sport80;
}

export function shouldOfferSport80$(
  fs: Firestore,
  channelId: string,
  userInfo: UserInfoWithDocId
): Observable<boolean> {
  return combineLatest([
    of(userHasSport80(channelId, userInfo)),
    getUsersSubscriptions(fs, channelId, userInfo.docId),
  ]).pipe(
    switchMap(([hasSport80, subscriptions]) => {
      // If user has sport80, skip the offer
      if (hasSport80) return of(false);
      if (subscriptions.some(isSubscriptionStillValid)) return of(false);
      // Otherwise if channel has defined sport80 then offer it
      return getSport80Settings$(fs, channelId, userInfo.docId);
    }),
    map(Boolean)
  );
}
