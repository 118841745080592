import { IdConfiguration } from "google-one-tap";

import { environment } from "@environment";
import { LoggingService } from "@app/services";
import { isWebview } from "./browser";

export function initGoogleOneTap(
  callback: IdConfiguration["callback"]
): boolean | null {
  if (isWebview()) return null;

  if (!environment.googleOAuthClientId) return null;

  const { google } = window;

  if (!google) {
    LoggingService.setBreadcrumb(
      "Could not find Google One Tap library",
      "SOCIAL"
    );
    return null;
  }

  google.accounts.id.initialize({
    client_id: environment.googleOAuthClientId,
    cancel_on_tap_outside: true,
    callback,
  });

  google.accounts.id.prompt();

  return true;
}
