import { FirebaseError } from "@angular/fire/app";
import { Firestore } from "@angular/fire/firestore";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  Auth,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  CustomParameters,
  FacebookAuthProvider,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  signInWithCredential,
  signInWithEmailAndPassword,
  signInWithPopup,
  User,
  verifyPasswordResetCode,
} from "@angular/fire/auth";

import { BehaviorSubject, Observable, filter, first, from } from "rxjs";
import { CredentialResponse } from "google-one-tap";
import {
  getAuthenticatedUserInfo$,
  UserInfoWithDocId,
} from "@yoimo/client-sdk/users";
import {
  ArenaAuthRequest,
  AuthRequest,
  StudioAuthRequest,
  TvAuthRequest,
} from "@yoimo/interfaces";

import { AuthProvider } from "@app/model";
import { AUTH_QUERY_PARAMS, getQueryParam } from "@app/utils";
import { AppWorkflowType, LoggingService } from ".";

const supportedProviders = {
  facebook: FacebookAuthProvider,
  google: GoogleAuthProvider,
} as const;

@Injectable({ providedIn: "root" })
export class AuthService {
  private _user$ = new BehaviorSubject<User | null>(null);

  constructor(
    readonly auth: Auth,
    private fs: Firestore,
    private http: HttpClient
  ) {}

  async signUp(user: { email: string; password: string }) {
    return createUserWithEmailAndPassword(this.auth, user.email, user.password);
  }

  async logIn(account: { email: string; password: string }): Promise<string> {
    const response = await signInWithEmailAndPassword(
      this.auth,
      account.email,
      account.password
    );
    return await response.user.getIdToken();
  }

  /**
   * Authenticate using a popup from an enabled provider.
   *
   * @param providerId Provider to authenticate with
   * @param isRetry Force the consent screen
   * @returns User ID token
   */
  async logInWithSocial(
    providerId: AuthProvider,
    isRetry?: boolean
  ): Promise<string> {
    LoggingService.setBreadcrumb(`Log-in attempt with "${providerId}"`, "AUTH");

    const provider = new supportedProviders[providerId]();
    const params: CustomParameters = { prompt: "select_account" };

    if (isRetry) params.auth_type = "rerequest";

    provider.addScope("email");
    provider.setCustomParameters(params);

    const response = await signInWithPopup(this.auth, provider);
    return await response.user.getIdToken();
  }

  /**
   * @remarks Specific for Google OneTap Sign-In
   */
  async logInWithCredential(credentials: CredentialResponse) {
    return signInWithCredential(
      this.auth,
      GoogleAuthProvider.credential(credentials.credential)
    );
  }

  async logOut(): Promise<void> {
    return this.auth.signOut();
  }

  getUser$(mustBeLoggedIn: true): Observable<User>;
  getUser$(mustBeLoggedIn?: false): Observable<User | null>;
  getUser$(mustBeLoggedIn?: undefined): Observable<User | null>;
  getUser$(mustBeLoggedIn?: boolean): Observable<User | null> {
    return this._user$.asObservable().pipe(
      filter((user) => {
        if (!user && mustBeLoggedIn) return false;
        return true;
      })
    );
  }

  getPasswordRelatedError(error: FirebaseError): string | null {
    if (error.code !== "auth/password-does-not-meet-requirements") {
      return null;
    }

    // Try to extract the requirements from the error message
    const message = error.message.match(/\[(.*?)\]/);
    // Set result as the extracted message or fallback to the complete message
    return message ? message[1] : error.message;
  }

  /** For JoymoTV users */
  getSignInUrl$(
    idToken: string,
    channelId: string,
    redirectUrl: string
  ): Observable<string> {
    return this.doAuthV2Request<TvAuthRequest, string>({
      action: "GET_SIGNIN_URL",
      channelId,
      platform: "TV",
      redirectUrl,
      idToken,
    });
  }

  getStudioSignInUrl$(
    idToken: string,
    redirectUrl: string
  ): Observable<string> {
    return this.doAuthV2Request<StudioAuthRequest, string>({
      action: "GET_SIGNIN_URL",
      platform: "STUDIO",
      redirectUrl,
      idToken,
    });
  }

  /**
   * Request a session cookie to authenticate the user in its corresponding domain(s)
   *
   * @deprecated For Arena users only
   */
  setSessionCookie$(uid: string, idToken: string): Observable<void> {
    return this.doAuthV1Request<ArenaAuthRequest, void>({
      action: "login",
      platform: "ARENA",
      token: idToken,
      uid,
    });
  }

  /**
   * Applies the password change
   *
   * @returns Email of the user, if success
   */
  async resetPassword(resetCode: string, newPassword: string): Promise<string> {
    const accountEmail = await verifyPasswordResetCode(this.auth, resetCode);
    await confirmPasswordReset(this.auth, resetCode, newPassword);
    return accountEmail;
  }

  /** Starts a request to change the password */
  requestPasswordReset$(
    workflow: AppWorkflowType,
    email: string
  ): Observable<void> {
    if (workflow === "ARENA") {
      return from(sendPasswordResetEmail(this.auth, email));
    }
    return this.doAuthV2Request<TvAuthRequest, void>({
      action: "REQUEST_PASSWORD_RESET",
      channelId: getQueryParam(AUTH_QUERY_PARAMS.CHANNEL_ID),
      email,
      platform: "TV",
    });
  }

  /**
   * Emit a value on `user$` when the user authenticates
   */
  setUser(user: User | null): void {
    if (!user) return;

    this.getUserDocument$()
      .pipe(first((res) => res !== undefined))
      .subscribe((_userWithDocId) => this._user$.next(user));
  }

  getUserDocument$(): Observable<UserInfoWithDocId> {
    return getAuthenticatedUserInfo$(this.auth, this.fs);
  }

  private doAuthV2Request<Req extends AuthRequest, Res>(
    payload: Req
  ): Observable<Res> {
    return this.doAuthRequest<Req, Res>("/authentication-v2", payload, false);
  }

  /** @deprecated */
  private doAuthV1Request<Req extends AuthRequest, Res>(
    payload: Req
  ): Observable<Res> {
    return this.doAuthRequest<Req, Res>("/authentication", payload, true);
  }

  private doAuthRequest<Req, Res>(
    endpoint: string,
    payload: Req,
    withCredentials: boolean
  ): Observable<Res> {
    return this.http.post<Res>(endpoint, payload, {
      headers: { "Content-Type": "application/json" },
      withCredentials,
      responseType: "text" as any,
    });
  }
}
