<nav class="auth-header p-12 md:p-x-24 md:p-y-16">
  <section class="flex flex-align-center gap-16">
    <ng-container *ngIf="channel$ | async as channel">
      <a [attr.href]="getChannelUrl(channel)" queryParamsHandling="merge">
        <img
          [src]="channel.branding.logoImage"
          [alt]="channel.name"
          class="auth-header--logo"
        />
      </a>
      <span
        class="auth-header--logo-divider block border-default border-l"
      ></span>
    </ng-container>
    <a [href]="joymoUrl()" target="_blank">
      <yo-icon
        [icon]="joymoIcon()"
        size="auto"
        class="auth-header--joymo"
      ></yo-icon>
    </a>
  </section>
</nav>
