import { Firestore } from "@angular/fire/firestore";
import { inject } from "@angular/core";
import { ResolveFn, Router } from "@angular/router";

import { BrandingService } from "@yoimo/joymo-ui";
import { ChannelWithDocId, getChannel } from "@yoimo/client-sdk/channels";
import { firstValueFrom } from "rxjs";

import { AUTH_QUERY_PARAMS } from "@app/utils";
import { MetaService, ScopeService } from "@app/services";
import { environment } from "@environment";

/** Resolve and set the current scope to a channel, based only on `channel_id` */
export function resolveChannel(
  forceNull?: boolean
): ResolveFn<ChannelWithDocId | null> {
  return async (route) => {
    const brandingService = inject(BrandingService);
    const metaService = inject(MetaService);
    const scopeService = inject(ScopeService);
    const channelId = route.queryParamMap.get(AUTH_QUERY_PARAMS.CHANNEL_ID);

    if (forceNull || !channelId) {
      metaService.setFavicon("DEFAULT");
      scopeService.setScope(null);
      brandingService.setTheme(environment.meta.defaultBranding, true);
      return null;
    }

    const fs = inject(Firestore);
    const router = inject(Router);

    const channel = await firstValueFrom(getChannel(fs, channelId));

    if (!channel) {
      router.navigate(["/404"], { skipLocationChange: true });
      throw new Error("ChannelNotFound");
    }

    brandingService.setTheme(channel.branding, true);
    metaService.setFavicon(channel.docId);
    scopeService.setScope({
      channel,
      scope: "CHANNEL",
      scopeId: channel.docId,
    });

    return channel;
  };
}
