import { ChannelWithDocId, Scope } from "@yoimo/client-sdk/channels";
import { Observable, ReplaySubject, map } from "rxjs";

import { Injectable } from "@angular/core";
import { environment } from "@environment";

@Injectable({ providedIn: "root" })
export class ScopeService {
  private _scope$ = new ReplaySubject<Scope | null>(1);

  setScope(scope: Scope | null): void {
    if (!scope) {
      this._scope$.next(null);
      this._scope$.complete();
      return;
    }
    this._scope$.next(scope);
  }

  getScope$(): Observable<Scope | null> {
    return this._scope$.asObservable();
  }

  getChannel$(mustBeDefined: true): Observable<ChannelWithDocId>;
  getChannel$(mustBeDefined?: undefined): Observable<ChannelWithDocId | null>;
  getChannel$(
    mustBeDefined: true | undefined
  ): Observable<ChannelWithDocId | null> {
    return this.getScope$().pipe(
      map((scope) => {
        const channel = scope ? this.getChannel(scope) : null;

        if (mustBeDefined && !channel) throw new Error("ChannelMustBeDefined");

        return channel;
      })
    );
  }

  /**
   * @deprecated Prefer `this.getChannel$`
   */
  getChannel = (scope: Scope) => {
    if (scope?.scope !== "CHANNEL") {
      throw new Error("Scope is not a channel");
    }
    return scope.channel;
  };

  static getCanonicalUrl(channel: ChannelWithDocId): string {
    return new URL(
      channel.slug,
      `https://${channel.domain || environment.meta.defaultDomain}`
    ).href;
  }
}
