@if (customPrivacyPolicy$ | async; as privacyPolicy) {
<ul>
  @for (line of privacyPolicy; track $index) {
  <li>
    <span *yoFormatText="line"></span>
  </li>
  }
</ul>
} @else {
<p>
  <a [href]="defaultLinks.privacy" target="_blank" rel="noopener noreferrer"
    >Joymo's Privacy Policy</a
  >
  and
  <a [href]="defaultLinks.tos" target="_blank" rel="noopener noreferrer"
    >Terms of Service</a
  >
</p>
}
