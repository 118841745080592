import { CanActivateFn, Router, UrlTree } from "@angular/router";
import { inject } from "@angular/core";

import { Auth } from "@angular/fire/auth";

/** Redirect anonymous users to the desired redirection path */
export function isLoggedInGuard(redirectTo: string): CanActivateFn {
  return (): true | UrlTree => {
    const auth = inject(Auth);
    const router = inject(Router);

    if (auth.currentUser) return true;
    const currentUrl = new URL(document.location.href);
    const queryParams = [...currentUrl.searchParams.entries()].reduce(
      (res, [key, value]) => {
        res[key] = value;
        return res;
      },
      {} as any
    );
    return router.createUrlTree([redirectTo], { queryParams });
  };
}

/** Log out users before anything else */
export const preSignOutGuard: CanActivateFn = async () => {
  await inject(Auth).signOut();
  return true;
};
