import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";

@Injectable({ providedIn: "root" })
export class ResetPasswordGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (!route.queryParams.oobCode) {
      this.router.navigate(["/"], { queryParamsHandling: "preserve" });
      return false;
    }
    return true;
  }
}
