import { Component } from "@angular/core";

import { ScopeService } from "@app/services";
import { PrivacyPolicyComponent } from "@app/features/privacy-policy/privacy-policy.component";

@Component({
  selector: "app-footer",
  standalone: true,
  imports: [PrivacyPolicyComponent],
  templateUrl: "./footer.component.html",
})
export class FooterComponent {
  readonly currentYear = new Date().getFullYear();
  readonly channel$ = this.scopeService.getChannel$();

  constructor(private scopeService: ScopeService) {}
}
