import { Injectable } from "@angular/core";
import { CanActivate, Router, UrlTree } from "@angular/router";

import { LoggingService } from "@app/services";

import { AUTH_QUERY_PARAMS, getQueryParam } from "@app/utils";

const REQUIRED_QUERY_PARAMS = [
  AUTH_QUERY_PARAMS.CHANNEL_ID,
  AUTH_QUERY_PARAMS.REDIRECT_URL,
  AUTH_QUERY_PARAMS.SLUG,
];

@Injectable({ providedIn: "root" })
export class ScopeGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(): true | UrlTree {
    const foundQueryParams = REQUIRED_QUERY_PARAMS.map((p) =>
      getQueryParam(p, "")
    );
    if (foundQueryParams.every(Boolean)) {
      return true;
    }
    LoggingService.logError("MissingQueryParams");
    return this.router.createUrlTree(["/404"]);
  }
}
