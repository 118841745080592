import { Component } from "@angular/core";
import { AsyncPipe } from "@angular/common";

import { FormatTextDirective } from "@yoimo/joymo-ui";
import { map } from "rxjs";

import { ScopeService } from "@app/services";
import { environment } from "@environment";

@Component({
  selector: "app-privacy-policy",
  standalone: true,
  imports: [AsyncPipe, FormatTextDirective],
  templateUrl: "./privacy-policy.component.html",
})
export class PrivacyPolicyComponent {
  readonly defaultLinks = {
    tos: environment.joymo.tosUrl,
    privacy: environment.joymo.privacyPolicy,
  };
  readonly appName = environment.meta.company;
  readonly customPrivacyPolicy$ = this.scopeService
    .getChannel$()
    .pipe(
      map(
        (channel) => channel?.branding.contactInformation?.privacyPolicy || null
      )
    );

  constructor(private scopeService: ScopeService) {}
}
