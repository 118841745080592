import { HttpClientModule } from "@angular/common/http";
import { RouterLink, RouterOutlet } from "@angular/router";
import {
  AfterViewInit,
  Component,
  NgZone,
  OnInit,
  Signal,
  computed,
} from "@angular/core";

import {
  BrandingService,
  BreadcrumbsComponent,
  IconModule,
} from "@yoimo/joymo-ui";
import { EMPTY, catchError, switchMap } from "rxjs";

import { initGoogleOneTap, isValidEmail } from "./utils";
import { APP_ROUTES } from "./app.routes";
import { CoverImageComponent } from "./layout/cover-image/cover-image.component";
import { FooterComponent } from "./layout/footer/footer.component";
import { HeaderComponent } from "./layout/header/header.component";
import {
  AppStateService,
  AppWorkflowService,
  AuthService,
  LoggingService,
  RedirectionService,
} from "@app/services";
import { NgStyle } from "@angular/common";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  standalone: true,
  imports: [
    BreadcrumbsComponent,
    CoverImageComponent,
    FooterComponent,
    HeaderComponent,
    HttpClientModule,
    IconModule,
    NgStyle,
    RouterLink,
    RouterOutlet,
  ],
})
export class AppComponent implements OnInit, AfterViewInit {
  readonly homepageUrl: Signal<string>;

  constructor(
    private authService: AuthService,
    private appState: AppStateService,
    private redirectionService: RedirectionService,
    private ngZone: NgZone,
    private workflowService: AppWorkflowService
  ) {
    this.homepageUrl = computed(() => {
      const workflow = this.workflowService.workflow();
      return APP_ROUTES[workflow!] || "";
    });
  }

  async ngOnInit(): Promise<void> {
    BrandingService.applyCssConstants();
    await this.authService.logOut();
    this.authService.auth.onAuthStateChanged((user) => {
      if (!user) return;
      if (!isValidEmail(user.email)) {
        this.appState.setError("joymo-auth/missing-email-permission");
        user.delete();
        return;
      }

      // User is logged in, emit it:
      LoggingService.linkToUser(user);
      this.authService.setUser(user);
    });

    this.authService
      .getUser$(true)
      .pipe(
        switchMap(() => this.redirectionService.goToRedirectUrl()),
        catchError((err: Error) => {
          this.appState.setError(err.message);
          return EMPTY;
        })
      )
      .subscribe();
  }

  ngAfterViewInit() {
    if (this.canNavigateBack()) {
      // Disable OneTap on inner routes
      return;
    }

    initGoogleOneTap((credentials) => {
      this.ngZone.run(() => {
        // The NgZone wrapper is required since this piece of async code
        // is triggered from outside the Angular scope
        this.appState.setState("loading");
        this.authService.logInWithCredential(credentials).catch((err) => {
          this.appState.setError(err);
        });
      });
    });
  }

  canNavigateBack(): boolean {
    return [/login\/with-email/, /signup\/with-email/, /forgot-password$/].some(
      (p) => location.pathname.match(p)
    );
  }
}
