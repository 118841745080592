import { semanticParsing } from "@yoimo/client-sdk/pages";
import { ParserImplementation } from "@yoimo/joymo-ui";

export class TextParser implements ParserImplementation {
  parse(input: string, document: Document): Array<HTMLElement | Text> {
    const semanticText = semanticParsing(
      input,
      new URL(document.location.href)
    );
    if (!semanticText) return [document.createTextNode(input)];
    return semanticText.map((node) => {
      if (typeof node === "string") return document.createTextNode(node);

      const link = document.createElement("a");
      link.setAttribute("href", node.target.toString());
      link.setAttribute("target", "_blank");
      link.setAttribute("rel", "noopener");
      link.innerText = node.text;
      return link;
    });
  }
}
