import { CommonModule } from "@angular/common";
import { Component, Signal, computed } from "@angular/core";

import { ChannelWithDocId } from "@yoimo/client-sdk/channels";
import { IconModule } from "@yoimo/joymo-ui";

import { AppWorkflowService, ScopeService } from "@app/services";
import { environment } from "@environment";

@Component({
  imports: [CommonModule, IconModule],
  selector: "app-header",
  standalone: true,
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
})
export class HeaderComponent {
  readonly channel$ = this.scopeService.getChannel$();
  readonly joymoIcon: Signal<string>;
  readonly joymoUrl: Signal<string>;

  constructor(
    private workflowService: AppWorkflowService,
    private scopeService: ScopeService
  ) {
    this.joymoIcon = computed(() => {
      if (this.workflowService.workflow() === "ARENA") return "joymo";
      return "powered-by-joymo";
    });
    this.joymoUrl = computed(() => {
      if (this.workflowService.workflow() === "ARENA")
        return environment.joymo.arenaUrl!;
      return environment.joymo.joymoUrl;
    });
  }

  getChannelUrl(channel: ChannelWithDocId): string {
    return ScopeService.getCanonicalUrl(channel);
  }
}
