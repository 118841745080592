import { appConfig } from "./app/app.config";
import { enableProdMode } from "@angular/core";
import { environment } from "@environment";
import { bootstrapApplication } from "@angular/platform-browser";
import { AppComponent } from "@app/app.component";

if (environment.production) {
  enableProdMode();
} else {
  console.warn("Running on staging");
}

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err)
);
