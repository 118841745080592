import { Environment } from "./environment.tpl";

const DEFAULT_DOMAIN = "joymo-arena-20-staging.web.app";

export const environment: Environment = {
  joymo: {
    arenaUrl: "https://staging.arena.joymo.no",
    acceptableUsePolicyUrl: "https://www.joymo.tv/acceptable-use-policy",
    joymoUrl: "https://joymo.tv",
    privacyPolicy: "https://www.joymo.tv/privacy-policy",
    tosUrl: "https://www.joymo.tv/terms-of-service",
  },
  sentryDsn:
    "https://1b412262a393419185c2e199174cc6fd@o1234650.ingest.sentry.io/4504298087448576",
  googleOAuthClientId:
    "662686805404-iffr44sr6k99vovh647c8snaq7g2icha.apps.googleusercontent.com",
  firebase: {
    apiKey: "AIzaSyAJyUKKe9MzNJpHEHRU7P-PU2E9_1ye10w",
    appId: "1:662686805404:web:0795a00eb7cbffa22aec59",
    authDomain: "yoimo-live-staging.firebaseapp.com",
    databaseURL: "https://yoimo-live-staging.firebaseio.com",
    measurementId: "G-XK49PYTETG",
    messagingSenderId: "662686805404",
    projectId: "yoimo-live-staging",
    storageBucket: "yoimo-live-staging.appspot.com",
    locationId: "us-central1",
  },
  meta: {
    baseAppUrl: "https://auth.staging.joymo.no",
    company: "Joymo",
    defaultDomain: DEFAULT_DOMAIN,
    domainAliases: {
      [DEFAULT_DOMAIN]: null,
      localhost: null,
    },
    defaultBranding: {
      accentColor: "#00A83C",
      backgroundColor: "#FFFFFF",
      themeStyle: "rounded",
    },
  },
  behavior: {
    authProviders: {
      google: {
        allowsWebview: false,
        id: "google",
        isEnabled: true,
        name: "Google",
      },
      facebook: {
        allowsWebview: true,
        id: "facebook",
        isEnabled: true,
        name: "Facebook",
      },
    },
    testFeatures: {
      favicons: {
        DEFAULT: "joymo.png",
      },
    },
  },
  shareApp: {
    name: "Joymo Share",
    iconUrl: "/assets/share-app/logo-joymo.webp",
    appStore: {
      downloadUrl: "https://apps.apple.com/no/app/joymo-share/id1482394966",
      downloadUrlQrCode: "/assets/share-app/qr-appstore.png",
      marketplaceName: "App Store",
      badgeUrl:
        "https://apple-resources.s3.amazonaws.com/media-badges/download-on-the-app-store/black/en-us.svg",
    },
    playStore: {
      downloadUrl:
        "https://play.google.com/store/apps/details?id=no.joymo.share",
      downloadUrlQrCode: "/assets/share-app/qr-playstore.png",
      marketplaceName: "Play Store",
      badgeUrl: "/assets/share-app/generic_playstore-badge.png",
    },
  },
};
