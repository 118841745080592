/* istanbul ignore file */
import { Firestore } from "@angular/fire/firestore";
import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { User } from "@angular/fire/auth";

import {
  combineLatest,
  EMPTY,
  firstValueFrom,
  from,
  Observable,
  switchMap,
  take,
} from "rxjs";

import { AUTH_QUERY_PARAMS, getQueryParam } from "@app/utils";
import { AuthService, ConsentService } from "./index";
import { environment } from "@environment";
import { shouldOfferSport80$ } from "@app/features/integrations/integrations.utils";
import { AppWorkflowService } from "./workflow.service";

@Injectable({ providedIn: "root" })
export class RedirectionService {
  private alreadyOfferedSport80?: boolean;
  constructor(
    private authService: AuthService,
    private consentService: ConsentService,
    private fs: Firestore,
    private router: Router,
    private route: ActivatedRoute,
    private workflowService: AppWorkflowService
  ) {}

  /** @param apiVersion Deprecated */
  async goToRedirectUrl(): Promise<void> {
    const workflow = this.workflowService.workflow();
    const user = await firstValueFrom(this.authService.getUser$(true));

    const returnTo = this.route.snapshot.queryParamMap.get(
      AUTH_QUERY_PARAMS.RETURN_TO
    );
    if (returnTo) {
      /** Internal redirections (i.e. within the app) */
      await this.router.navigateByUrl(returnTo);
      return;
    }

    if (workflow === "ARENA") {
      return this.goToArena(
        user,
        getQueryParam(
          AUTH_QUERY_PARAMS.REDIRECT_URL,
          environment.joymo.arenaUrl
        )
      );
    }
    const idToken = await user.getIdToken();
    if (workflow === "TV") {
      return this.goToJoymoTv(
        idToken,
        user.uid,
        getQueryParam(AUTH_QUERY_PARAMS.CHANNEL_ID),
        getQueryParam(AUTH_QUERY_PARAMS.REDIRECT_URL)
      );
    }
    if (workflow === "STUDIO") {
      return this.goToStudio(
        idToken,
        getQueryParam(AUTH_QUERY_PARAMS.REDIRECT_URL)
      );
    }
    throw new Error("NotImplemented");
  }

  private goToArena(user: User, arenaUrl: string): void {
    from(user.getIdToken())
      .pipe(
        switchMap((idToken) => {
          return this.authService.setSessionCookie$(user.uid, idToken);
        })
      )
      .subscribe(() => location.assign(arenaUrl));
  }

  private goToJoymoTv(
    token: string,
    userId: string,
    channelId: string,
    redirectUrl: string
  ): void {
    combineLatest([
      this.consentService.isConsentEntryPending$(channelId, userId),
      this.authService
        .getUserDocument$()
        .pipe(switchMap((u) => shouldOfferSport80$(this.fs, channelId, u))),
    ])
      .pipe(
        take(1),
        switchMap(([askForConsent, offerSport80]): Observable<string> => {
          // Run the appropriate checks to decide what to do next
          if (askForConsent) {
            this.router.navigate(["/", "tv", "privacy"], {
              queryParamsHandling: "preserve",
            });
            return EMPTY;
          }
          if (offerSport80 && !this.alreadyOfferedSport80) {
            this.alreadyOfferedSport80 = true;
            this.router.navigate(["/tv/integrations", "sport80"], {
              queryParamsHandling: "preserve",
            });
            return EMPTY;
          }
          // Nothing else is required, proceed to channel
          return this.authService.getSignInUrl$(token, channelId, redirectUrl);
        })
      )
      .subscribe((signInUrl) => location.replace(signInUrl));
  }

  private goToStudio(idToken: string, redirectUrl: string): void {
    this.authService
      .getStudioSignInUrl$(idToken, redirectUrl)
      .subscribe((signInUrl) => location.replace(signInUrl));
  }
}
