import { CanActivateFn, Router } from "@angular/router";
import { inject } from "@angular/core";

import { map, of, switchMap, throttleTime } from "rxjs";
import { WindowService } from "@yoimo/joymo-ui";

import { AppStateService, AuthService } from "@app/services";
import { AUTH_QUERY_PARAMS, getQueryParam } from "@app/utils";

/**
 * Enforces a required user email
 *
 * @param fallbackUrl Redirect to this URL if any check fails
 */
export function boundUserGuard(fallbackUrl: string): CanActivateFn {
  return (_route, state) => {
    const appStateService = inject(AppStateService);
    const authService = inject(AuthService);
    const router = inject(Router);
    const windowService = inject(WindowService);

    return authService.getUser$().pipe(
      throttleTime(300), // FIXME: refactor this chain so it doesn't run into race conditions
      switchMap((user) => {
        const boundEmail = getQueryParam(AUTH_QUERY_PARAMS.BOUND_EMAIL);

        if (user?.email === boundEmail) return of(true);

        return of(authService.logOut()).pipe(
          map(() => {
            appStateService.setState("idle");

            windowService.window?.alert(
              `You must log in as ${boundEmail} to access this invite`
            );

            return router.createUrlTree([fallbackUrl], {
              queryParams: {
                ...state.root.queryParams,
                [AUTH_QUERY_PARAMS.BOUND_EMAIL]: boundEmail,
                [AUTH_QUERY_PARAMS.RETURN_TO]: state.url,
              },
            });
          })
        );
      })
    );
  };
}
