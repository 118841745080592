import { AUTH_ERRORS, AUTH_PROVIDER_PLACEHOLDER } from "@app/model";

import { Auth, fetchSignInMethodsForEmail } from "@angular/fire/auth";
import {
  EnvironmentInjector,
  inject,
  runInInjectionContext,
} from "@angular/core";

export function isValidEmail(email: string | null): boolean {
  if (!email) return false;
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/gi.test(email);
}
/**
 * Based on an error code, check if user already holds a registered account
 */
export function isUserAlreadyRegistered(fromErrorCode: string): boolean {
  return ["auth/account-exists", "auth/wrong-password"].some((prefix) =>
    fromErrorCode.startsWith(prefix)
  );
}

/**
 * @param email User email to verify
 * @param allowPasswordProvider Allow the email to be linked to the "Password" provider
 * @returns An error message describing which auth provider the user signed up with previously, or null if there's none
 */
export async function getAlreadyRegisteredError(
  injector: EnvironmentInjector,
  email: string,
  allowPasswordProvider: boolean,
  replacePlaceholderWith = "Joymo"
): Promise<string | null> {
  const provider = await fetchAuthProviders(injector, email);
  if (!provider) return null;

  const errorMsg = AUTH_ERRORS["auth/account-exists-with-different-credential"];

  if (provider !== "Password")
    return errorMsg.replaceAll(AUTH_PROVIDER_PLACEHOLDER, provider);
  if (allowPasswordProvider) return null;

  return errorMsg.replace(AUTH_PROVIDER_PLACEHOLDER, replacePlaceholderWith);
}

async function fetchAuthProviders(
  injector: EnvironmentInjector,
  email: string
): Promise<string> {
  const auth = runInInjectionContext(injector, () => inject(Auth));
  let [provider = "", _otherProviders] = await fetchSignInMethodsForEmail(
    auth,
    email
  );

  // Format provider name
  // e.g. "google.com" -> "Google"
  // e.g. "password" -> "Joymo"
  provider = provider.split(".")[0];
  provider = provider.charAt(0).toUpperCase() + provider.slice(1);

  return provider;
}
