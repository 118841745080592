import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Firestore } from "@angular/fire/firestore";
import { Injectable } from "@angular/core";

import { Observable, switchMap, catchError, of, map } from "rxjs";
import { InviteErrors } from "@yoimo/interfaces";
import { InviteWithDocId, getInvite } from "@yoimo/client-sdk/users";

import { AuthService, LoggingService } from "@app/services";

@Injectable({ providedIn: "root" })
export class InviteResolver implements Resolve<InviteWithDocId | null> {
  constructor(private authService: AuthService, private fs: Firestore) {}

  resolve(route: ActivatedRouteSnapshot): Observable<InviteWithDocId | null> {
    const { inviteId } = route.params;

    return this.authService.getUser$(true).pipe(
      switchMap(() => getInvite(this.fs, inviteId)),
      map((invite) => {
        return invite;
      }),
      catchError((e) => {
        if (e.message !== InviteErrors.Expired) {
          LoggingService.logError(e.message);
        }
        return of(null);
      })
    );
  }
}
