<app-cover-image class="bg-solid none lg:block"></app-cover-image>

<div data-app-header>
  <app-header></app-header>
  <yo-breadcrumbs
    [ngStyle]="{ visibility: canNavigateBack() ? 'visible' : 'hidden' }"
    class="block p-12 lg:p-24"
  >
    <a
      yo-breadcrumb
      [routerLink]="homepageUrl()"
      queryParamsHandling="preserve"
      class="flex flex-align-center"
    >
      <yo-icon icon="arrow_left" size="lg" class="m-r-8"></yo-icon>
      Back to all options
    </a>
  </yo-breadcrumbs>
</div>

<div
  data-app-content
  class="flex flex-column flex-justify-between overflow-y-auto"
>
  <main app-google-onetap class="w-100 standard-margins m-b-64 p-b-16">
    <router-outlet></router-outlet>
    <app-footer class="block m-t-32"></app-footer>
  </main>
</div>
