import { AsyncPipe } from "@angular/common";
import { Component } from "@angular/core";

import { Observable, map } from "rxjs";

import { ScopeService } from "@app/services/scope.service";
import { environment } from "@environment";

@Component({
  selector: "app-cover-image",
  standalone: true,
  imports: [AsyncPipe],
  template: `
    @if (cover$ | async; as cover) {
    <img [src]="cover.image" [alt]="cover.name" />
    }
  `,
  styles: `
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  `,
})
export class CoverImageComponent {
  readonly cover$: Observable<{ name: string; image: string }>;

  constructor(private scopeService: ScopeService) {
    this.cover$ = this.scopeService.getChannel$().pipe(
      map((channel) => {
        if (!channel) {
          return {
            name: environment.meta.company,
            image: "assets/generic_cover.jpg",
          };
        }
        return {
          name: channel.name,
          image: channel.branding.bannerImages["16/9"],
        };
      })
    );
  }
}
