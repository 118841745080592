import { Injectable, signal, WritableSignal } from "@angular/core";

export type AppWorkflowType = "TV" | "STUDIO" | "ARENA";

@Injectable({ providedIn: "root" })
export class AppWorkflowService {
  private _workflow: WritableSignal<AppWorkflowType | null> = signal(null);
  readonly workflow = this._workflow.asReadonly();

  setWorkflowType(type: AppWorkflowType): AppWorkflowType {
    this._workflow.set(type);
    return type;
  }
}
