/* istanbul ignore file */

import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { catchError, of } from "rxjs";

import { AppStateService } from "@app/services";
import { Injectable } from "@angular/core";
import { environment } from "@environment";

@Injectable()
export class APIInterceptor implements HttpInterceptor {
  constructor(private appStateService: AppStateService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const apiReq = req.clone({ url: environment.meta.baseAppUrl + req.url });
    return next.handle(apiReq).pipe(
      catchError((e) => {
        if (e instanceof HttpErrorResponse) {
          this.appStateService.setError(e.error);
        }
        return of(e);
      })
    );
  }
}
